// Hindi
export default{
    form: {
        startSurveyButton: "शुरू करें",
        nextButton: "आगे",
        submitAnswerButton: "उत्तर सबमिट करें",
        continueButton: "जारी रखें",
        finishButton: "समाप्त",
        headerPercentageCompletion: 'पूरा हुआ',
        headerQuestionsCompleted: 'प्रश्न',
        headerDefaultErrorMessage: 'उफ़। कुछ गलत हो गया।',
        brandingPoweredBy: 'द्वारा संचालित',
        brandingPromoWhySurvey: 'जब आप <b>{product}</b> का उपयोग कर सकते हैं तो सर्वेक्षण क्यों करें?',
        brandingPromoGetStarted: 'आज ही शुरू करें!',
        submissionPending: "आपका वॉइसफॉर्म सबमिट हो रहा है। कृपया अपना ब्राउज़र बंद न करें।",
        submissionCanCloseWindow: "आपका वॉइसफॉर्म सफलतापूर्वक सबमिट हो गया है। अब आप विंडो बंद कर सकते हैं 👍",
        mediaAttachmentOverlayCloseButton: "बंद करें",
        expiredFormHeader: "आने के लिए धन्यवाद!",
        expiredFormMessage: "ऐसा लगता है कि यह सर्वेक्षण समाप्त हो गया है। कृपया वॉइसफॉर्म क्रिएटर से संपर्क करें।",
        notPublishedFormHeader: "वॉइसफॉर्म प्रकाशित नहीं किया गया है!",
        notPublishedFormMessage: "ऐसा लगता है कि यह सर्वेक्षण प्रकाशित नहीं हुआ है। कृपया वॉइसफॉर्म क्रिएटर से संपर्क करें।",
        notPublishedFormRetryButton: "फिर से कोशिश करें",
        errorFormHeader: "उफ़!",
        errorFormMessage: "शायद कुछ गलत हो गया है। कृपया बाद में फिर से प्रयास करें।",
        errorFormRetryButton: "फिर से कोशिश करें",
        emptyFormHeader: "आने के लिए धन्यवाद!",
        emptyFormMessage: "उफ़! ऐसा लगता है कि यह फॉर्म फिलहाल खाली है और इसमें कोई प्रश्न या फ़ील्ड नहीं हैं। असुविधा के लिए हमें खेद है। कृपया इस समस्या के बारे में क्रिएटर से संपर्क करें। धन्यवाद!",

        restoreFormHeader: "आपके पास एक प्रगति में फॉर्म है!",
        restoreFormMessage: "क्या आप वहीं से जारी रखना चाहेंगे जहाँ आपने छोड़ा था?",
        restoreFormButton: "फॉर्म जारी रखें",
        restoreFormButtonNew: "नया शुरू करें",

        audioPermissionHeader: "क्या आप आवाज से उत्तर देना चाहेंगे?",
        audioPermissionMessage: "बोलना तेज़ और आसान जवाब देने में मदद करेगा।",
        audioPermissionAcceptButton: "हाँ, मैं चाहता हूँ",
        audioPermissionDenyButton: "नहीं, धन्यवाद",
        audioPermissionErrorHeader: "माइक्रोफ़ोन उपलब्ध नहीं है",
        audioPermissionErrorMessage: "माइक्रोफ़ोन एक्सेस की अनुमति देने के लिए कृपया अपने ब्राउज़र की अनुमतियाँ जांचें।",
        audioPermissionErrorButton: "ठीक है",
        audioRecorderFailedUpload: "रिकॉर्डिंग अपलोड करने में विफल",
        silenceDetectorError: "हम आपको सुन नहीं पा रहे हैं। कृपया अपने माइक्रोफोन की सेटिंग्स जांचें। टिप: यह आपका बाहरी माइक्रोफोन हो सकता है।",
        audioRecorderButtonRecord: "रिकॉर्ड करें",
        audioRecorderButtonRecordMore: "अधिक रिकॉर्ड करें",
        voiceResponseSelectionMessage: "आप किस तरह से उत्तर देना चाहते हैं, चुनें...",
        voiceResponseSelectionOrDivider: "या",
        voiceResponseTextInputPlaceholder: "उत्तर टाइप करें",
        voiceResponseTextInputCharacterCounterMinMessage: "कृपया कम से कम {min} वर्ण दर्ज करें",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} वर्ण (न्यूनतम {min} वर्ण)",
        voiceResponseTextInputCharacterValidationMessage: "{count} वर्ण (न्यूनतम {min} वर्ण)",

        // checkbox
        checkboxValidationTooFew: "आपको कम से कम {min} विकल्प चुनने होंगे",
        checkboxValidationTooMany: "आपको {max} से अधिक विकल्प नहीं चुनने चाहिए",
        checkboxNoneOfTheAboveOption: "इनमें से कोई नहीं",
        checkboxInvalidAnswer: "अमान्य उत्तर।",

        // datepicker
        datePickerPlaceholder: "तिथि चुनें",

        // dropdown
        // email
        emailLabel: "ईमेल",
        emailInvalid: "अमान्य ईमेल।",
        // file-upload
        fileUploadPluginNameCamera: "कैमरा",
        fileUploadPluginNameCameraVideo: "वीडियो रिकॉर्ड करें",
        fileUploadPluginScreenCast: "स्क्रीन कास्ट",
        fileUploadVideoImportFilesDefault: 'रिकॉर्ड करें या एक वीडियो अपलोड करें:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'वीडियो रिकॉर्डिंग विकल्प चुनें:',
        fileUploadVideoImportFilesNoCamera: 'स्क्रीन रिकॉर्ड करने के लिए बटन दबाएं',
        fileUploadVideoImportFilesNoScreenCast: 'वीडियो रिकॉर्ड करने के लिए बटन दबाएं',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'कोई विकल्प उपलब्ध नहीं है',

        // matrix
        matrixValidationMessage: "उत्तर मान्य है।",
        matrixRow: "पंक्ति",
        matrixColumn: "स्तंभ",
        matrixRowRequired: "पंक्ति {rowTitle} आवश्यक है।",
        matrixRadioGroup: "रेडियो समूह",
        matrixCheckboxGroup: "चेकबॉक्स समूह",
        matrixGroupRequired: "पंक्ति {rowTitle} के लिए {type} {groupTitle} आवश्यक है।",
        matrixColumnRequired: "पंक्ति {rowTitle} के लिए स्तंभ {columnTitle} आवश्यक है।",
        matrixColumnInvalid: "पंक्ति {rowTitle} के लिए स्तंभ {columnTitle} अमान्य है।",
        matrixRequired: "आवश्यक।",
        matrixNumericMustBeNumber: "संख्या होनी चाहिए।",
        matrixNumericMustBeGreaterThenMin: "{min} से अधिक होना चाहिए।",
        matrixNumericMustBeLessThenMax: "{max} से कम होना चाहिए।",
        matrixNumericMustBeInteger: "पूर्णांक होना चाहिए।",
        matrixNumericInvalidNumber: "अमान्य संख्या।",

        // name
        nameLabel: "नाम",
        nameInvalid: "पहला और अंतिम नाम दर्ज करें।",

        // nps
        npsNotLikely: "बिल्कुल नहीं",
        npsExtremelyLikely: "बहुत संभव",

        // numeric input
        numericInputRequired: "आवश्यक।",
        numericInputMustBeNumber: "संख्या होनी चाहिए।",
        numericInputMustBeGreaterThenMin: "{min} से अधिक होना चाहिए।",
        numericInputMustBeLessThenMax: "{max} से कम होना चाहिए।",
        numericInputMustBeInteger: "पूर्णांक होना चाहिए।",
        numericInputInvalidNumber: "अमान्य संख्या।",
        numericInputPlaceholder: "संख्या दर्ज करें",

        // phone
        phoneInvalid: "अमान्य फोन नंबर।",
        phoneCountrySelectorLabel: 'देश कोड',
        phoneCountrySelectorError: 'देश चुनें',
        phoneNumberLabel: 'फ़ोन नंबर',
        phoneExample: 'उदाहरण:',

        // boolean
        booleanYesLabel: "हाँ",
        booleanNoLabel: "नहीं",

        //questionStep
        questionStepAudioQuestionLabel: "ऑडियो प्रश्न",

        // errors
        invalidPhoneNumber: "{phone} अमान्य फ़ोन नंबर है।",
        invalidEmail: "{email} अमान्य ईमेल पता है।",
        questionIsRequired: "यह प्रश्न आवश्यक है।",
        answerIsNotValid: "उत्तर मान्य नहीं है।",
        unfinishedProbingDialogError: "कृपया संवाद समाप्त करें।",
        cannotResumePartialResponse: "आंशिक उत्तर को फिर से शुरू नहीं कर सकते।",
        failedToSubmitForm: "फॉर्म सबमिट करने में विफल। कृपया अपने इंटरनेट कनेक्शन की जांच करें और पुनः प्रयास करें।"
    }
}
